import {
  SeatStateSingleDayComponnentProgressiveLoad,
  SpaceStateComponentProgressiveLoad,
  ResourceStateFilterComponent,
  SeatStateMultiDayComponnentProgressiveLoad,
  YourDeskMarker,
  SeatAvatarComponent,
} from '@robinpowered/perseus';
import {
  useGetStartTimesForSelectedDates,
  useGetDeskEndTimesForSelectedDates,
  useGetSpaceEndTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import {
  useCurrentFilter,
  useSpaceFilterInitialized,
  useFilteredSpaceIds,
  useMapInteractiveLayers,
  useDeskFilterInitialized,
  useFilteredDeskIds,
} from 'atoms/mapInteractions';
import { useMemo } from 'react';
import { useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const MapData = () => {
  const { t } = useTranslation('map');
  const { timezone } = useTimezone();
  const { currentUser, permissions } = useAuthContext();
  const mapInteractiveLayers = useMapInteractiveLayers();
  const currentFilter = useCurrentFilter();
  const filteredSpaceIds = useFilteredSpaceIds();
  const spaceFilterInitialized = useSpaceFilterInitialized();
  const filteredDeskIds = useFilteredDeskIds();
  const deskFilterInitialized = useDeskFilterInitialized();

  const startTimes = useGetStartTimesForSelectedDates();
  const availabilityStartTime = useMemo(() => {
    if (startTimes && startTimes.length > 0) {
      return startTimes[0];
    }
    return null;
  }, [startTimes]);

  const endDeskTImes = useGetDeskEndTimesForSelectedDates();
  const endTimeForDesks = useMemo(() => {
    if (endDeskTImes && endDeskTImes.length > 0) {
      return endDeskTImes[0];
    }
    return null;
  }, [endDeskTImes]);

  const endSpaceTimes = useGetSpaceEndTimesForSelectedDates();
  const endTimeForSpaces = useMemo(() => {
    if (endSpaceTimes && endSpaceTimes.length > 0) {
      return endSpaceTimes[0];
    }
    return null;
  }, [endSpaceTimes]);

  const spaceAvailabilityDuration = useMemo(
    () =>
      (availabilityStartTime &&
        endTimeForSpaces &&
        endTimeForSpaces.diff(availabilityStartTime, 'minutes')) ||
      undefined,
    [availabilityStartTime, endTimeForSpaces]
  );
  const deskAvailabilityDuration = useMemo(
    () =>
      (availabilityStartTime &&
        endTimeForDesks &&
        endTimeForDesks.diff(availabilityStartTime, 'minute')) ||
      undefined,
    [availabilityStartTime, endTimeForDesks]
  );
  const multiDayAvailabilityDates = useGetStartTimesForSelectedDates() || [];
  const deskEndTimeForSelectedDates =
    useGetDeskEndTimesForSelectedDates() || [];

  const deskFirstStartTime = multiDayAvailabilityDates[0];
  const deskLastEndTime =
    deskEndTimeForSelectedDates.length > 0
      ? deskEndTimeForSelectedDates[deskEndTimeForSelectedDates.length - 1]
      : undefined;

  const excludedUsers = currentUser ? [currentUser.id] : [];

  const avatarSearchStartTime = useMemo(() => {
    return deskFirstStartTime
      ? deskFirstStartTime
          .clone()
          .startOf('day')
          .toISOString()
          .replace(/\.\d+/, '')
      : undefined;
  }, [deskFirstStartTime]);

  const avatarSearchEndTime = useMemo(() => {
    return deskLastEndTime
      ? deskLastEndTime.clone().endOf('day').toISOString().replace(/\.\d+/, '')
      : undefined;
  }, [deskLastEndTime]);

  return (
    <>
      {mapInteractiveLayers.has('seats') &&
        multiDayAvailabilityDates.length === 1 &&
        avatarSearchStartTime &&
        avatarSearchEndTime && (
          <SeatAvatarComponent
            excludeUserIdentities={new Set(excludedUsers)}
            startTime={avatarSearchStartTime}
            endTime={avatarSearchEndTime}
            zoomLimit={{
              low: 2,
              medium: 3,
              high: 4.5,
            }}
            showAnonymous={permissions.canReserveDesksForOthers}
          />
        )}
      {mapInteractiveLayers.has('seats') &&
        currentUser &&
        currentUser.id &&
        (multiDayAvailabilityDates.length > 1 ? (
          <SeatStateMultiDayComponnentProgressiveLoad
            startTime={multiDayAvailabilityDates[0].format('H:m')}
            durationInMinutes={deskAvailabilityDuration || 15}
            dates={multiDayAvailabilityDates.map((x) => x.format('YYYY-MM-DD'))}
            timezone={timezone}
            seatPerLoadCycle={10}
            userId={Number(currentUser.id)}
          ></SeatStateMultiDayComponnentProgressiveLoad>
        ) : multiDayAvailabilityDates.length === 1 ? (
          <SeatStateSingleDayComponnentProgressiveLoad
            seatPerLoadCycle={10}
            startTime={
              availabilityStartTime
                ? multiDayAvailabilityDates[0]
                    .toISOString()
                    .replace(/\.\d+/, '')
                : null
            }
            endTime={
              endTimeForDesks
                ? multiDayAvailabilityDates[0]
                    .clone()
                    .add(deskAvailabilityDuration, 'minute')
                    .toISOString()
                    .replace(/\.\d+/, '')
                : null
            }
            userId={Number(currentUser.id)}
          />
        ) : (
          <></>
        ))}

      {(mapInteractiveLayers.has('spaces') ||
        mapInteractiveLayers.has('space_labels')) &&
        multiDayAvailabilityDates.length > 0 && (
          <SpaceStateComponentProgressiveLoad
            spacePerLoadCycle={10}
            startTime={multiDayAvailabilityDates[0]
              .toISOString()
              .replace(/\.\d+/, '')}
            duration={spaceAvailabilityDuration}
            dates={multiDayAvailabilityDates.map((x) =>
              x.toISOString().replace(/\.\d+/, '')
            )}
          />
        )}

      {currentFilter === 'spaces' && spaceFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredSpaceIds}
          layer="spaces"
        />
      )}

      {currentFilter === 'seats' && deskFilterInitialized && (
        <ResourceStateFilterComponent
          resourceIds={filteredDeskIds}
          layer="seats"
        />
      )}

      {deskFirstStartTime && deskLastEndTime && currentUser && (
        <YourDeskMarker
          startTime={deskFirstStartTime.toISOString().replace(/\.\d+/, '')}
          endTime={deskLastEndTime.toISOString().replace(/\.\d+/, '')}
          userId={currentUser.id}
          text={t('your_desk')}
        />
      )}
    </>
  );
};
